<template>
    <div class="Profile-attendance">
        <div class="Profile-attendance__select-block">
            <form-group class="mr-4">
                <label class="form-label">{{ $t('profile.profile.class-placeholder') }}</label>
                <el-select
                    class="w-100"
                    autocomplete="nope"
                    v-model="selectedClass"
                    @change="getStudents"
                    :placeholder="$t('profile.profile.class-placeholder')"
                >
                    <el-option v-for="studentClass in classes"
                               :key="studentClass.id"
                               :value="studentClass.id"
                               :label="studentClass.name">
                    </el-option>
                </el-select>
            </form-group>

            <form-group>
                <label class="form-label">{{ $t('profile.performance.student-placeholder') }}</label>
                <el-select
                    class="w-100"
                    autocomplete="nope"
                    v-model="selectedStudent"
                    @change="getData"
                    :placeholder="$t('profile.performance.student-placeholder')"
                >
                    <el-option
                        v-for="(student, id) in students"
                        :key="id"
                        :label="student"
                        :value="id">
                    </el-option>
                </el-select>
            </form-group>
        </div>
        <div class="Profile-attendance__cards" v-if="selectedStudent">
            <div class="attendance-percentage-card" v-loading.lock="loading">
                <div class="attendance-percentage-card__title">
                    {{$t("profile.attendance.title-1")}}
                </div>
                <div class="attendance-percentage-card__info">
                    <div class="attendance-percentage-card__circle"></div>
                    <div class="attendance-percentage-card__percent">
                        <span v-if="data.attended">{{ Math.floor((data.attended / data.total) * 100) }}%</span>
                        <span v-else>0%</span>
                        {{$t("profile.attendance.attend")}}
                    </div>
                </div>
                <div class="attendance-percentage-card__info">
                    <div class="attendance-percentage-card__circle attendance-percentage-card__circle--delay"></div>
                    <div class="attendance-percentage-card__percent">
                        <span v-if="data.late">{{ Math.floor((data.late / data.total) * 100) }}%</span>
                        <span v-else>0%</span>
                        {{$t("profile.attendance.delay")}}
                    </div>
                </div>
                <div class="attendance-percentage-card__info">
                    <div class="attendance-percentage-card__circle attendance-percentage-card__circle--pass"></div>
                    <div class="attendance-percentage-card__percent">
                        <span v-if="data.absent">{{ Math.floor((data.absent / data.total) * 100) }}%</span>
                        <span v-else>0%</span>
                        {{$t("profile.attendance.pass")}}
                    </div>
                </div>
                <div class="attendance-percentage-card__desc">
                    {{$t("profile.attendance.attend-desc")}}
                </div>
            </div>
            <div class="attendance-card" v-loading.lock="loading">
                <div class="attendance-card__title">
                    {{$t("profile.attendance.title-2")}}
                </div>
                <div class="attendance-card__content">
                    <div class="attendance-card__chart">
                        <vue-apex-charts width="250" height="250" type="donut" :options="chartOptions" :series="series"></vue-apex-charts>
                    </div>
                    <div class="attendance-card__period">
                        <div class="attendance-card__select-title">
                            {{$t("profile.attendance.select-month")}}
                        </div>
                        <div class="attendance-card__scrolls">
                            <scroll-picker :disabled="loading" @input="getData" v-model="selectedMonth" :options="optionMonth"></scroll-picker>
                            <scroll-picker :disabled="loading" @input="getData" v-model="selectedYear" :options="optionYear"></scroll-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Profile-attendance__without-result" v-if="!selectedStudent">{{ $t('profile.performance.select-student-class') }}</div>
    </div>
</template>
<script>

import "vue-scroll-picker/dist/style.css"
import VueApexCharts from 'vue-apexcharts'

import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker"
export default {
    components: {
        ScrollPicker,
        ScrollPickerGroup,
        VueApexCharts
    },
    data() {
        return {
            loading: false,
            classes: [],
            selectedClass: '',
            students: [],
            selectedStudent: '',
            series: [0, 0, 0],
            chartOptions: {
                labels: [this.$t("profile.attendance.attend"), this.$t("profile.attendance.delay"), this.$t("profile.attendance.pass")],
                chart: {
                    type: 'donut',
                    dropShadow: {
                        enabled: false,
                    }
                },
                plotOptions: {
                    pie: {
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    colors: ['#473F95', '#DE9A06', '#D23168']
                },
                legend: {
                    show: false,
                },
            },
            data: {},
            optionYear: [
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022,
                2023,
                2024,
                2025,
            ],
            optionMonth: [
                { name: this.$t('profile.attendance.jan'), value: 1 },
                { name: this.$t('profile.attendance.feb'), value: 2 },
                { name: this.$t('profile.attendance.mar'), value: 3 },
                { name: this.$t('profile.attendance.apr'), value: 4 },
                { name: this.$t('profile.attendance.may'), value: 5 },
                { name: this.$t('profile.attendance.jun'), value: 6 },
                { name: this.$t('profile.attendance.jul'), value: 7 },
                { name: this.$t('profile.attendance.aug'), value: 8 },
                { name: this.$t('profile.attendance.sep'), value: 9 },
                { name: this.$t('profile.attendance.okt'), value: 10 },
                { name: this.$t('profile.attendance.nov'), value: 11 },
                { name: this.$t('profile.attendance.dec'), value: 12 }
            ],
            selectedMonth: 0,
            selectedYear: 0,
        };
    },
    mounted() {
        let date = new Date()
        this.selectedMonth = date.getMonth() + 1
        this.selectedYear = date.getFullYear()
        // this.getData()
        this.getClasses()
    },
    methods: {
        getClasses() {
            this.loading = true
            this.$http.get(`${window.API_ROOT}/api/reference/teacher-classes`)
                .then((res) => {
                    this.classes = res.body.data
                    this.loading = false
                }).catch(() => {
                this.loading = false
            })
        },
        getStudents(classId) {
            this.loading = true
            this.$http.get(`${window.API_ROOT}/api/teacher/students/${classId}`)
                .then((res) => {
                    this.students = res.body
                    this.loading = false
                }).catch(() => {
                this.loading = false
            })
        },
        getData() {
            this.loading = true
            this.$http.post(`${window.API_ROOT}/api/journal/attendance/${this.selectedYear}/${this.selectedMonth}`, {user_id: this.selectedStudent})
                .then((res) => {
                    this.loading = false
                    this.data = res.body
                    this.series = [res.body.attended, res.body.late, res.body.absent]
                }).catch(() => {
                this.loading = false
            })
        }
    },
}
</script>
<style lang="less" scoped>
.form-label {
    display: none;
}
.Profile-attendance__cards {
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
}
.Profile-attendance__select-block {
    display: flex;
  width: 100%;
    margin-bottom: 5px;
}
.Profile-attendance__select-block .form-group {
    width: 260px;
}
/deep/ .Profile-attendance__select-block .el-select-dropdown__item.selected {
    color: #D23168!important;
}

.Profile-attendance__without-result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 459px;
    font-size: 35px;
    line-height: 41px;
    font-weight: bold;
    color: #A39FCA;
    // border: 1px solid #DFE0EB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 100px;
}
.attendance-percentage-card {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
     max-width: 40%;
    padding: 20px;
    min-height: 363px;
    font-family: 'Roboto', sans-serif;
    margin-right: 20px;
}
.attendance-percentage-card__title,
.attendance-card__title {
    color: #473F95;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 12px;
    border-bottom: 2px solid #EEEAFB;
    margin-bottom: 36px;
}
.attendance-percentage-card__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 12px;
}
.attendance-percentage-card__circle {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #473F95;
    margin-right: 12px;
}
.attendance-percentage-card__circle--delay {
    background-color: #DE9A06;
}
.attendance-percentage-card__circle--pass {
    background-color: #D23168;
}
.attendance-percentage-card__percent {
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
}
.attendance-percentage-card__desc {
    font-size: 14px;
    line-height: 16px;
    color: #D23168;
    padding-top: 16px;
    border-top: 2px solid #D23168;
    margin-top: 62px;
}
.attendance-card {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    max-width: 616px;
    padding: 20px;
    min-height: 363px;
    font-family: 'Roboto', sans-serif;
    min-width: 60%;
}
.attendance-card__select-title {
    color: #473F95;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 36px;
}
.attendance-card__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 46px;
}
.attendance-card__period {
    padding-right: 18px;
}
.attendance-card__scrolls {
    display: flex;
    flex-direction: row;
}
.vue-scroll-picker {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    min-width: 110px;
}
/deep/ .vue-scroll-picker-list .vue-scroll-picker-list-rotator .vue-scroll-picker-item {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}
/deep/ .vue-scroll-picker-list .vue-scroll-picker-list-rotator .vue-scroll-picker-item.-selected {
    color: #D23168;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}
.vue-scroll-picker-layer .bottom {
    color: #D8CEFB;
}
.attendance-card__chart {
    //width: 246px;
    //height: 246px;
    //border-radius: 50%;
    //background-color: #473F95;
}
@media (max-width: 1100px) {
    .Profile-attendance__cards {
        flex-direction: column-reverse;
    }
    .attendance-card {
        margin-bottom: 20px;
    }
}
@media (max-width: 991px) {
    .Profile-attendance__cards {
        margin-bottom: 0;
    }
    .Profile-attendance__without-result {
        margin: 0 auto;
        letter-spacing: 0.3px;
        font-size: 24px;
        line-height: 28px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background-image: url("/images/profile/performance-bg-mob.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        min-height: 530px;
        padding: 0 32px;
        text-align: center;
    }
    .Profile-attendance__select-block .form-group {
        display: flex;
        flex-direction: column;
    }
    .attendance-percentage-card {
        max-width: 100%;
        margin-right: 0;
    }
    .attendance-card {
        width: 100%;
        max-width: 100%;
        box-shadow: none;
        padding-top: 0;
    }
    .attendance-card__title {
        display: none;
    }
}
@media (max-width: 767px) {
    .Profile-attendance__select-block {
        flex-direction: column;
    }
    .attendance-card__content {
        flex-direction: column-reverse;
        padding-left: 0;
        align-items: center;
    }
    .attendance-card__select-title {
        text-align: center;
        padding-bottom: 15px;
    }
    .attendance-card__period {
        padding-right: 0;
    }
    .attendance-percentage-card {
        padding: 16px 48px;
    }
}

@media (max-width: 575px) {
    .Profile-attendance__select-block .form-group .el-select {
        width: 100%;
        max-width: none;
    }
    .Profile-attendance__select-block .form-group {
        width: 100%;
    }
}
</style>